export const getInitials = name => {
  if (!name) {
    return '';
  }

  const nameArray = name.split(' ');

  return `${nameArray[0] && nameArray[0].length > 0 ? nameArray[0][0] : ''}${
    nameArray[1] && nameArray[1].length > 0 ? nameArray[1][0] : ''
  }`;
};

export const getFormData = obj => {
  const formData = new FormData();
  Object.keys(obj).forEach(k => {
    formData.append(k, obj[k]);
  });
  return formData;
};

export const mergeValidator = (...validator) => {
  return value => {
    for (let i = 0; i < validator.length; i += 1) {
      const res = validator[i](value);
      if (res) {
        return res;
      }
    }
    return null;
  };
};
