import styled from 'styled-components';

export const SiteWrapper = styled.div`
  display: flex;
  height: 100%;
  background: ${props => props.theme.palette.colors.body};
`;

export const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.palette.colors.white};
`;

export const MainContentWrapper = styled.main`
  display: flex;
  flex: 1;
  margin: ${props => props.theme.spacing(1)};
  padding: ${props => props.theme.spacing(0, 3)};
`;
