const USER = 'userObj'; // TODO: use 'user'. Patch for old sites

export const setUser = user => localStorage.setItem(USER, JSON.stringify(user));

export const deleteUser = () => localStorage.removeItem(USER);

export const getUser = () => {
  const userObj = JSON.parse(localStorage.getItem(USER));
  if (!userObj) {
    return null;
  }
  return {
    name: userObj.name,
    perms: userObj.perms,
    token: userObj.token,
    groups: userObj.groups,
  };
};
