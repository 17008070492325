import styled from 'styled-components';

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  color: ${props => props.theme.palette.contrastText[props.color]};
  background: ${props => props.theme.palette.colors[props.color]};
  border-radius: 50%;
`;

export const AvatarImage = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
`;
