import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Typography } from 'Components/UI';
import { lighten } from 'Components/Utils';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.palette.colors.white};
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.144956);
`;

export const SiteNavWrapper = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const LinkCSS = css`
  display: flex;
  align-items: center;
  margin: ${props => props.theme.spacing(0.5, 0)};
  padding: ${props => props.theme.spacing(2, 4)};
  color: ${props => props.theme.palette.colors.subtext};
  text-decoration: none;
  cursor: pointer;

  svg {
    margin-right: ${props => props.theme.spacing(2)};
  }

  &.active,
  &:hover {
    color: ${props => props.theme.palette.colors.primary};
    background: ${props => lighten(props.theme.palette.colors.primary, 0.85)};
  }
`;

export const NavItem = styled(NavLink)`
  ${LinkCSS}
`;

export const LogoWrapper = styled.div`
  margin: ${props => props.theme.spacing(2, 0)};
  padding: ${props => props.theme.spacing(2, 4)};
`;

export const UserMenuWrapper = styled.div`
  background: #fafbfb;
`;

export const UserMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(2, 1)};

  &.logout {
    cursor: pointer;
  }
`;

export const UserMenuIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  margin: ${props => props.theme.spacing(0, 0.5)};
`;

export const UserMenuText = styled(Typography)`
  width: 70%;
`;
