import React, { useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { Toast } from 'Components/UI';

import { TABS_MAPPING } from 'Pages';
import { selectors, actions } from 'State/user';
import { APP_LINKS, BASE_URL } from 'Utilities/constants';

import ErrorBoundary from './ErrorBoundary';
import { AppLayout } from './Layout';
import { SiteProvider } from './SiteContext';

const onChangeNetwork = () => {
  if (navigator.onLine) {
    Toast('Connected', { type: Toast.TYPE.SUCCESS });
  } else {
    Toast('You are offline.', { type: Toast.TYPE.ERROR, autoClose: false });
  }
};

const App = ({
  isLoggedIn,
  logoutUser,
  userName,
  userPermission,
  allowedTabs,
}) => {
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  useEffect(() => {
    window.addEventListener('online', onChangeNetwork);
    window.addEventListener('offline', onChangeNetwork);

    return () => {
      window.removeEventListener('online', onChangeNetwork);
      window.removeEventListener('offline', onChangeNetwork);
    };
  }, [onChangeNetwork]);

  return (
    <ErrorBoundary>
      <SiteProvider value={{ permission: userPermission }}>
        <AppLayout
          sideBarProps={{
            userName,
            logoutUser,
            tabs: allowedTabs,
          }}
        >
          <React.Suspense fallback={<div>Loading.....</div>}>
            <Switch>
              {allowedTabs.map(t => (
                <Route
                  key={t.id}
                  path={APP_LINKS[t.id]}
                  component={TABS_MAPPING[t.id]}
                />
              ))}
              <Redirect from={BASE_URL} to={APP_LINKS.CONTENT} />
            </Switch>
          </React.Suspense>
        </AppLayout>
      </SiteProvider>
    </ErrorBoundary>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state),
  userName: selectors.getUserName(state),
  userPermission: selectors.userPermission(state),
  allowedTabs: selectors.getAllowedTabs(state),
});

export default connect(mapStateToProps, {
  logoutUser: actions.unsetUserData,
})(App);
