import React from 'react';

import { Icon } from 'Components/Icons';

const Content = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.17557 2.12195V5.99268V6.01157H9.51703V3.80507C9.51703 3.48718 9.77069 3.24409 10.1024 3.24409H19.4292L14.1804 5.82458C13.8097 6.01157 13.5561 6.40425 13.5561 6.81563V6.92783V10.5742V16.3335H10.1024C9.77069 16.3335 9.51703 16.0904 9.51703 15.7725V11.3221H7.17557V17.4555C7.17557 18.0727 7.7022 18.5774 8.3463 18.5774H13.5756V22.8782C13.5756 23.3085 14.0439 23.5701 14.4341 23.3831L23.3317 18.9699C23.7024 18.7829 23.9561 18.3902 23.9561 17.979V17.474V2.85122V2.12195C23.9561 1.50488 23.4292 1 22.7853 1H8.3463C7.7022 1 7.17557 1.50488 7.17557 2.12195ZM0.365812 7.80632L4.17069 4.15998C4.65849 3.6925 5.41947 3.67398 5.88776 4.12276C6.35606 4.55285 6.29752 5.31951 5.84854 5.74959C5.82903 5.74959 4.13167 7.37642 4.13167 7.37642H10.7853C10.9804 7.37642 11.1951 7.43252 11.3707 7.52602C12.0731 7.9 12.2097 8.89106 11.6634 9.45203C11.4487 9.69512 11.1168 9.82602 10.7853 9.82602H4.13186L5.84874 11.4528C6.29771 11.9016 6.35606 12.6498 5.88796 13.0984C5.40015 13.5659 4.65849 13.547 4.17069 13.0795L0.365812 9.43315C-0.121992 8.96567 -0.121992 8.2738 0.365812 7.80632Z"
    />
  </Icon>
);

export default Content;
