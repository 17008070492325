import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  :root {
    box-sizing: border-box;
    font-size: ${props => props.theme.config.font.rootSize}px;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  ul,
  ol {
    padding: 0;
    list-style: none;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }

  body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    color: ${props => props.theme.palette.colors.text};
    font-family: ${props => props.theme.config.font.family};
    ${props => props.theme.typography.body}
    background-color: ${props => props.theme.palette.colors.body};
  }

  *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
 
  *::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
 
  *::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 3px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  *::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 0, 0, 0.4);
  }

  .margin-horizontal {
    margin: ${props => props.theme.spacing(0, 1)};
  }

  .position-top-right {
    z-index: 900;
  }

  input:disabled,
  .dropdown-input,
  .dropdown-list,
  .react-datepicker__month-container {
    background-color: ${props => props.theme.palette.colors.white};
  }
`;

export default GlobalStyle;
