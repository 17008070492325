export const BASE_URL = '/app';

export const APP_LINKS = {
  LOGIN: '/login',
  ERROR: '/error',
  CONTENT: `${BASE_URL}/content`,
  BANNERS: `${BASE_URL}/banners`,
  CATALOGUE: `${BASE_URL}/catalogue`,
};

export const TABS = {
  CONTENT: {
    label: 'Content',
    id: 'CONTENT',
    iconName: 'content',
    iconSize: '24',
  },
  BANNERS: {
    label: 'Banners',
    id: 'BANNERS',
    iconName: 'banners',
    iconSize: '24',
  },
  CATALOGUE: {
    label: 'Catalogue',
    id: 'CATALOGUE',
    iconName: 'catalogue',
    iconSize: '24',
    permissions: ['CatalogView', 'CatalogTabView'],
  },
};
