import React from 'react';

import { Typography } from 'Components/UI';

import reportAppError from 'Utilities/errorHandlers';

const ErrorComponent = () => (
  <>
    <Typography align="center" variant="h1">
      Something went wrong. Please contact agrostar support team
    </Typography>
  </>
);

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Customized error handling goes here!
    reportAppError(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorComponent />;
    }

    return children;
  }
}

export default ErrorBoundary;
