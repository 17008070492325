import React from 'react';

import { Typography } from 'Components/UI';

import AppIcons from 'Components/AppIcons';
import Logo from 'Components/AppIcons/Logo';
import Logout from 'Components/AppIcons/Logout';
import Avatar from 'Components/Avatar';
import { getInitials } from 'Utilities';
import { deleteUser } from 'Utilities/localStorage';

import {
  LogoWrapper,
  NavItem,
  SidebarWrapper,
  SiteNavWrapper,
  UserMenuWrapper,
  UserMenuItem,
  UserMenuText,
  UserMenuIcon,
} from './styled';

const UserMenu = ({ logout, userName }) => (
  <UserMenuWrapper>
    <UserMenuItem key="username">
      <UserMenuIcon>
        <Avatar size="40" text={getInitials(userName)} />
      </UserMenuIcon>
      <UserMenuText variant="h2">{userName}</UserMenuText>
    </UserMenuItem>
    <UserMenuItem key="logout" className="logout" onClick={logout}>
      <UserMenuIcon>
        <Logout width="24" color="subtext" />
      </UserMenuIcon>
      <UserMenuText variant="h3" color="subtext">
        Logout
      </UserMenuText>
    </UserMenuItem>
  </UserMenuWrapper>
);

const Sidebar = ({ logoutUser, userName, tabs, appLinks }) => {
  const logout = () => {
    deleteUser();
    logoutUser();
  };

  return (
    <SidebarWrapper>
      <LogoWrapper>
        <Logo width="150" />
      </LogoWrapper>
      <SiteNavWrapper>
        {tabs.map(t => (
          <NavItem key={t.id} to={appLinks[t.id]} activeClassName="active">
            <AppIcons name={t.iconName} width={t.iconSize} />
            <Typography variant="h3">{t.label}</Typography>
          </NavItem>
        ))}
      </SiteNavWrapper>
      <UserMenu userName={userName} logout={logout} />
    </SidebarWrapper>
  );
};

export default Sidebar;
