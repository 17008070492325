import React from 'react';

import { Icon } from 'Components/Icons';

const Banners = props => (
  <Icon viewBox="0 0 21 20" {...props}>
    <g clipPath="url(#clip0)">
      <path d="M12.67 11.481c-.378 0-.692.128-.908.37a1.24 1.24 0 00-.301.832c0 .585.382 1.216 1.222 1.216.377 0 .692-.128.908-.37.194-.217.3-.512.3-.831 0-.586-.382-1.217-1.222-1.217zM7.85 8.544c.38 0 .694-.128.911-.369a1.23 1.23 0 00.301-.828c0-.584-.383-1.212-1.225-1.212-.858 0-1.243.6-1.243 1.196 0 .585.394 1.213 1.257 1.213z" />
      <path d="M19.233 12.054c.158-.697 1.239-1.31 1.239-2.054 0-.745-1.08-1.358-1.239-2.054-.164-.72.54-1.74.227-2.388-.318-.66-1.558-.744-2.01-1.31-.455-.57-.262-1.796-.832-2.251-.566-.452-1.719.006-2.378-.312-.65-.313-1.007-1.503-1.726-1.667-.696-.158-1.533.756-2.278.756-.745 0-1.581-.914-2.278-.755-.72.163-1.077 1.353-1.726 1.666-.66.319-1.812-.14-2.378.312-.57.455-.377 1.681-.832 2.251-.452.567-1.692.65-2.01 1.31-.313.649.39 1.67.227 2.389C1.081 8.642 0 9.256 0 10s1.08 1.358 1.239 2.054c.164.72-.54 1.74-.227 2.388.318.66 1.558.744 2.01 1.31.455.57.262 1.797.832 2.251.566.453 1.719-.006 2.378.312.65.313 1.007 1.503 1.726 1.667.697.158 1.534-.755 2.278-.755s1.582.913 2.278.755c.72-.164 1.077-1.353 1.726-1.667.66-.318 1.812.14 2.378-.312.57-.454.377-1.68.832-2.25.452-.567 1.692-.65 2.01-1.31.314-.65-.39-1.67-.227-2.389zM6.093 5.792c.426-.464 1.029-.71 1.744-.71 1.537 0 2.34 1.14 2.34 2.264 0 .591-.209 1.143-.588 1.555-.429.464-1.035.71-1.752.71-1.53 0-2.33-1.14-2.33-2.265 0-.59.208-1.142.585-1.554zm1.437 8.101a.65.65 0 01-.422.189h-.905c-.14 0-.205-.145-.112-.249l6.912-7.685a.668.668 0 01.422-.192l.831-.018c.23 0 .236.168.143.272l-6.87 7.683zm6.858.329c-.42.456-1.014.696-1.719.696-1.5 0-2.285-1.117-2.285-2.221 0-.58.204-1.12.574-1.525.418-.455 1.01-.697 1.711-.697 1.507 0 2.296 1.118 2.296 2.222 0 .58-.205 1.121-.577 1.525z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h20.472v20H0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default Banners;
