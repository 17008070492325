import React from 'react';

const LoginPage = React.lazy(() => import('./LoginPage'));
const ErrorPage = React.lazy(() => import('./ErrorPage'));
const ContentPage = React.lazy(() => import('./ContentPage'));
const BannersPage = React.lazy(() => import('./BannersPage'));
const CataloguePage = React.lazy(() => import('./CataloguePage'));

export const TABS_MAPPING = {
  CONTENT: ContentPage,
  BANNERS: BannersPage,
  CATALOGUE: CataloguePage,
};

export { LoginPage, ErrorPage };
