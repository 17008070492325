import { createSelector } from 'reselect';
// import _difference from 'lodash/difference';

import { TABS } from 'Utilities/constants';

export const isLoggedIn = state =>
  !!(state.user && state.user.name && state.user.token);

export const userPermission = state => state.user && state.user.perms;

export const getUserName = state => state.user && state.user.name;

export const getAuthToken = state => state.user && state.user.token;

export const getSource = state => state.user && state.user.source;

export const getAllowedTabs = createSelector([userPermission], permission => {
  const tabs = Object.values(TABS);
  return tabs.filter(tab => {
    if (tab.permissions && permission) {
      // return _difference(tab.permissions, permission).length === 0;
      return tab.permissions.some(z => permission.includes(z));
    }
    return true;
  });
});
