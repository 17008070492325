import React from 'react';

import Content from './Content';
import Banners from './Banners';
import Catalogue from './Catalogue';

const IconMapping = {
  content: Content,
  banners: Banners,
  catalogue: Catalogue,
};

const AppIcons = ({ name, ...restProps }) => {
  const IconComponent = IconMapping[name];
  return <IconComponent {...restProps} />;
};

export default AppIcons;
