import { createAgDesignTheme } from 'Components/UI';

const config = {
  font: {
    family: 'Open Sans, sans-serif',
  },
  spacing: 8,
};
const palette = {
  colors: {
    primary: '#127B02',
    body: '#E5E5E5',
    text: '#333333',
    label: '#9FA7B0',
    subtext: '#9B9B9B',
    note: '#eebf79',
    default: '#D8D8D8',
  },
  borderColors: {
    default: '#D8D8D8',
    divider: '#6F757C',
  },
};

const typography = {
  h2: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  h3: {
    fontSize: 15,
    fontWeight: '500',
  },
  body: {
    fontSize: 13,
    fontWeight: '500',
  },
  helpText: {
    fontSize: 12,
    fontWeight: 'normal',
  },
};

const theme = createAgDesignTheme({ config, palette, typography });

export default theme;
