import React from 'react';

import { Icon } from 'Components/Icons';

const Catalogue = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path d="M14.97 1.786c.239 0 .433.194.433.434 0 .98.46 1.853 1.167 2.424a5.263 5.263 0 011.954 3.86l.005.225v9.072c0 1.17-.927 2.123-2.081 2.195l-.14.004h-3.333c1.218 0 2.22-.982 2.22-2.2V8.73a5.26 5.26 0 00-1.958-4.086 3.14 3.14 0 01-1.057-1.605c.074-.26.113-.536.113-.82 0-.212.153-.389.355-.426l.078-.007h2.243zM14.94 0c.23 0 .42.168.457.388l.006.075v.414c0 .23-.168.42-.388.457l-.075.006h-2.185a.463.463 0 01-.457-.388l-.006-.075V.463c0-.23.168-.421.388-.457L12.755 0h2.185zM4.49 1.786a.433.433 0 00-.433.434c0 .98-.46 1.853-1.168 2.424a5.263 5.263 0 00-1.953 3.86l-.005.225v9.072c0 1.17.927 2.123 2.081 2.195l.14.004h3.333c-1.218 0-2.22-.982-2.22-2.2V8.73a5.26 5.26 0 011.958-4.086A3.14 3.14 0 007.28 3.039a2.987 2.987 0 01-.113-.82.433.433 0 00-.355-.426l-.078-.007H4.49zM4.52 0a.463.463 0 00-.457.388l-.006.075v.414c0 .23.168.42.388.457l.075.006h2.185c.23 0 .42-.168.456-.388l.006-.075V.463A.463.463 0 006.78.006L6.705 0H4.52z" />
    <path d="M12.404 4.644a3.114 3.114 0 01-1.168-2.424.433.433 0 00-.433-.434H8.559a.433.433 0 00-.433.434c0 .98-.46 1.853-1.167 2.424A5.253 5.253 0 005 8.729v9.072C5 19.019 5.996 20 7.215 20h4.926c1.219 0 2.221-.982 2.221-2.2V8.73a5.26 5.26 0 00-1.958-4.086zm.38 6.25v6.48a.962.962 0 01-.973.952H7.544a.956.956 0 01-.965-.952v-6.48h6.204zM10.773 0c.255 0 .462.207.462.463v.414a.463.463 0 01-.463.463H8.59a.463.463 0 01-.463-.463V.463c0-.256.207-.463.463-.463h2.184z" />
  </Icon>
);

export default Catalogue;
