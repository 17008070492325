import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  & .fixed-dropdown {
    position: relative;
  }
`;

export const SelectBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: ${props => props.theme.dsSpacing(3)};
  background: ${props => props.theme.palette.colors.default};
  border: 1px solid ${props => props.theme.palette.borderColors.default};
  border-radius: ${props => props.theme.config.borderRadius};
  cursor: pointer;

  ${props =>
    props.multiple &&
    css`
      border-radius: ${props => props.theme.config.borderRadius}; ${props => props.theme.config.borderRadius}; 0 0;
    `}

  & span {
    flex: 1;
  }
  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.palette.colors.error};
    `}
`;

export const DropdownListWrapper = styled.div`
  width: 100%;
  margin: ${props => props.theme.dsSpacing(0.25)};
  padding: ${props => props.theme.dsSpacing(3)};
  background: ${props => props.theme.palette.colors.default};

  ${props =>
    props.multiple &&
    css`
      margin: 0;
    `}
`;

export const ListWrapper = styled.div`
  max-height: 140px;
  padding: ${props => props.theme.dsSpacing(0, 1)};
  overflow-y: auto;
`;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.dsSpacing(3, 0, 3, 4)};
  border-bottom: 1px solid #d0d9f3;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      display: flex;
      justify-content: space-between;
      color: ${props => props.theme.palette.colors.primary};
    `}

  &:last-child {
    border-bottom: none;
  }

  &:hover span {
    color: ${props => props.theme.palette.colors.primary};
  }
`;
