import React from 'react';

import { AvatarWrapper, AvatarImage } from './styled';

const Avatar = ({ text, image, ...props }) => {
  return image ? (
    <AvatarImage src={image} {...props} />
  ) : (
    <AvatarWrapper {...props}>{text}</AvatarWrapper>
  );
};

Avatar.defaultProps = {
  color: 'default',
};

export default Avatar;
