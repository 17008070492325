import React from 'react';

import Sidebar from 'Components/Sidebar';
import { APP_LINKS } from 'Utilities/constants';

import { MainContentWrapper, SiteWrapper } from './styled';

export const SiteLayout = ({ children }) => {
  return <SiteWrapper>{children}</SiteWrapper>;
};

export const AppLayout = ({ sideBarProps, children }) => {
  return (
    <>
      <Sidebar {...sideBarProps} appLinks={APP_LINKS} />
      <MainContentWrapper>{children}</MainContentWrapper>
    </>
  );
};
