import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { ToastContainer } from 'Components/UI';

import { APP_LINKS, BASE_URL } from 'Utilities/constants';

import App from './app';
import { SiteLayout } from './app/Layout';
import { ErrorPage, LoginPage } from './pages';
import reduxStore from './store';
import theme from './theme';
import GlobalStyle from './theme/globalStyle';

const RenderApp = () => (
  <Provider store={reduxStore}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <BrowserRouter basename={`${PUBLIC_URL}/`}>
          <SiteLayout>
            <React.Suspense fallback={<div>Loading.....</div>}>
              <Switch>
                <Route path={APP_LINKS.LOGIN} component={LoginPage} />
                <Route path={APP_LINKS.ERROR} component={ErrorPage} />
                <Route path={BASE_URL} component={App} />
                <Redirect from="/" to={BASE_URL} />
              </Switch>
            </React.Suspense>
          </SiteLayout>
        </BrowserRouter>
        <ToastContainer position={ToastContainer.POSITION.TOP_RIGHT} />
      </>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(<RenderApp />, document.getElementById('root'));

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(`${PUBLIC_URL}/serviceWorker.js`).then(
      registration => {
        console.log(
          'ServiceWorker registration successful with scope: ',
          registration.scope,
        );
      },
      err => {
        console.log('ServiceWorker registration failed: ', err);
      },
    );
  });
}
