import React from 'react';

import { Icon } from 'Components/Icons';

const Content = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path d="M.778 8.793h18.444c.255 0 .486.095.636.26.073.081.157.214.14.39l-.903 9.076c-.031.314-.372.58-.776.58H1.924c-.396 0-.736-.26-.775-.567L.004 9.446a.493.493 0 01.132-.392c.147-.167.387-.261.642-.261zm1.82-1.257v-1.52c0-.438.371-.826.808-.826h13.188c.437 0 .752.388.752.825v1.52h1.676V4.039c0-.347-.219-.608-.566-.608h-7.395v-.374C11.061 1.879 10.306 1 9.325 1H2.664c-.98 0-1.741.88-1.741 2.056v4.48h1.675z" />
  </Icon>
);

export default Content;
